<template>
    <div class="page-aftersale-upload-img">
        <div class="table-header">
            <div class="left">探头数量</div>
            <div class="right">上传时间</div>
        </div>
        <div class="list-item" v-for="(item, index) in list" :key="index">
            <div class="left">{{item.size}}个</div>
            <div class="right">{{item.time}}</div>
        </div>
        <div class="loading-box">
            <Button
                loading
                v-if="loading && !isShowAll"
                type="default"
                loading-text="加载中..."
            />
            <Button
                type="default"
                v-if="!loading && !isShowAll"
                @click="loadMore"
            >点击加载更多</Button>
            <Button
                type="default"
                v-if="isShowAll"
            >{{list.length ? '已全部显示' : '暂无数据'}}</Button>
        </div>

        <div class="create-btn" @click="createItem">
            <div>新建</div>
            <div>记录</div>
        </div>
    </div>
</template>

<script>
import { Toast, Button } from "vant";
import { getYMD } from '@/utils/time'
export default {
    name: "RyUploadImgPage",
    components: {
        Button
    },
    data() {
        const { ossHost } = this.$config.base;
        return {
            ossHost,
            list: [],
            isShowAll: false,
            loading: false
        };
    },
    created() {
        const vm = this;
        // const { USER_NAME } = vm.$config.keys;
        // let name = vm.$localStorage.getItem(USER_NAME);
        const uuid = vm.$localStorage.getItem('uuid') || vm.$route.query.uuid;
        const fType = vm.$strTool.filterFactoryType(uuid);
        if (fType != 'yingwo') {
            vm.$router.replace({
                path: "404"
            });
            return
        }
        vm.pageNo = 1
        vm.loadList();
    },
    methods: {
        loadList() {
            const vm = this;
            const params = {
                pageNo: vm.pageNo,
                pageSize: 20
            }
            vm.loading = true
            vm.$http({
                type: "get",
                url: `${vm.$config.base.PROBE_BASE_URL}scrapProbe/list`,
                params,
                except: true
            }).then(res => {
                if (!res.data) {
                    Toast("网络错误");
                    return;
                }
                vm.loading = false;
                res.data.data.map(item => {
                    item.time = getYMD(item.uploadAt);
                })
                vm.list = vm.list.concat(res.data.data);
                vm.isShowAll = res.data.datalength < 20
            });
        },

        loadMore() {
            const vm = this
            vm.pageNo = vm.pageNo + 1;
            vm.loadList()
        },
        createItem() {
            this.$router.push({
                path: './addUploadImgPage'
            })
        }
    }
};
</script>

<style lang="less" scoped>
@import "@/less/base.less";
.page-aftersale-upload-img {
    width: 100%;
    height: 100%;
    background: @blank;
    position: relative;
    overflow-y: auto;
    color: @black;
    padding-top: 50px;
    .table-header {
        line-height: 50px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        font-size: 0.18rem;
        color: #303030;
        background: @blank;
        text-align: center;
        .left {
            width: 40%;
        }
        .right {
            width: 60%;
        }
    }
    .list-item {
        line-height: 40px;
        display: flex;
        font-size: 0.14rem;
        color: #515151;
        text-align: center;
        .left {
            width: 40%;
        }
        .right {
            width: 60%;
        }
    }
    .loading-box {
        padding-top: 10px;
        text-align: center;
    }
    .create-btn {
        position: fixed;
        right: 20px;
        bottom: 50px;
        width: 60px;
        height: 60px;
        border-radius: 30px;
        color: @themeColor;
        box-shadow: 0px 0px 10px 0px @themeColor;
        border: 2px solid @themeColor;
        background: @blank;
        font-size: 16px;
        display: flex;
        padding: 5px 11px;
        flex-direction: column;
    }
}
</style>
